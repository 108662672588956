<template>
  <Container :title="$t('withdrawFunds.pageTitle')">
    <v-row>
      <v-col>
        <p>
          {{ pageHint }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <template v-if="isCompany">
          <p>
            {{ $t('withdrawFunds.ifYouUsePaymentServiceProvider') }}
          </p>
        </template>
        <template v-else>
          <p>
            {{ $t('withdrawFunds.paymentsWithoutReferenceNumberAreRejected') }}
          </p>
        </template>
        <p>
          {{ $t('withdrawFunds.sepaArriveNextDay') }}
        </p>

        <v-col v-if="bankAccounts.length" cols="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select
              v-model="activeBankAccount"
              filled
              :items="bankAccounts"
              item-text="iban"
              item-value="id"
              :rules="[(v) => !!v || $t('errors.fieldRequired')]"
              :label="$t('withdrawFunds.bankAccount')"
              required
            />
            <v-text-field
              v-model="withdraw"
              filled
              required
              small
              :rules="validateRules"
              :label="$t('withdrawFunds.amountToWithdraw')"
            />
            <v-btn :dark="valid" large :loading="loading" :disabled="!valid || loading" @click="submit()" v-text="$t('withdrawFunds.transferOut')"/>
          </v-form>
        </v-col>
      </v-col>
    </v-row>
  </Container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "WithdrawalFunds",
  data() {
    return {
      activeBankAccount: null,
      loading: true,
      bankAccounts: [],
      withdraw: 0,
      valid: true,
      validateRules: [
        () => this.cleanedUp?.match(/^[0-9]*(\.[0-9]{1,2})?$/) || this.$t('errors.notValidAmount'),
        () => parseFloat(this.cleanedUp) > 0 || this.$t('errors.notValidAmount'),
        () => parseFloat(this.cleanedUp) <= this.balance || this.$t('errors.notEnoughMoney'),
      ],
    }
  },
  computed: {
    isCompany: function() {
      return this.$store.state.account.type === 'company'
    },
    investmentAccount: function() {
      return this.$store.state.account
    },
    balance: function() {
      return parseFloat(this.$store.state.account.balance)
    },
    cleanedUp() {
      return this.withdraw?.replace(/,/g, '.').replace(/ /g, '')
    },
    pageHint() {
      return this.investmentAccount.type !== 'company' ?
        this.$t('withdrawFunds.hintForPrivateAccount') :
        this.$t('withdrawFunds.hintForCompany')
    },
  },
  watch: {
    investmentAccount: {
      immediate: true,
      handler(value, oldValue) {
        if (value?.id != oldValue?.id) {
          this.activeBankAccount = null
          this.getCurrentBankAccount()
        }
      },
    },
  },
  methods: {
    getCurrentBankAccount() {
      if (this.investmentAccount.id) {
        this.loading = true
        this.$http
          .get(
            `/investment_accounts/${this.investmentAccount.id}/bank_accounts/`,
          )
          .then((accounts) => {
            this.bankAccounts = accounts.data.results
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    submit() {
      if (this.$refs.form.validate() && this.activeBankAccount) {
        this.loading = true
        this.$http
          .post(`/withdrawal_requests/`, {
            amount: this.cleanedUp,
            bank_account: this.activeBankAccount,
            investment_account: this.investmentAccount.id,
          })
          .then(() => {
            this.$auth.fetch()
            this.$store.commit('pushMessage', {
              icon: 'mdi-party-popper',
              text: this.$t('withdrawFunds.paymentWithdrawalRequestHasBeenCreated'),
            })
            this.$refs.form.reset()
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
  },
})
</script>
